<template>
    <div>
      <v-card :elevation="1" class="v-sheet theme--light br-0">
        <v-card-title>
          <v-row class="col-md-12">
            <v-col cols="12" sm="12" md="6">
              Periodos | Administración
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-btn
                color=""
                dark
                class="mb-2 float-right light-blue-502"
                @click="modalNuevo"
              >
                Registrar periodo
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="col-md-12 m-0 p-0">
            <v-divider></v-divider>
          </v-row>
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <v-data-table
            class="elevation-0"
            :headers="headers"
            :items="items"
            :search="filtro"
            :loading="tableLoading"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
              'items-per-page-text': 'Registros por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:top>
              <!-- v-container, v-col and v-row are just for decoration purposes. -->
              <v-container fluid>
                <v-row>
                  <v-col cols="6" md="6" sm="6" xs="6">
                    <v-text-field
                      autocomplete="off"
                      class=""
                      dense
                      filled
                      color="blue-grey lighten-2"
                      v-model="filtro"
                      label="Periodo"
                      maxlength="200"
                    ></v-text-field>
                  </v-col>
  
                  <v-col cols="6" md="6" sm="6" xs="6">
                    <v-select
                      v-model="estado"
                      :items="estados"
                      :loading="false"
                      dense
                      filled
                      label="Estado"
                      :no-data-text="'No existen estados registrados'"
                      menu-props="auto"
                      return-object
                    ></v-select>
                  </v-col>
  
                  <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                    <v-btn
                      class="ma-0 white--text d-none"
                      medium
                      color="light-blue darken-2"
                    >
                      <v-icon left>mdi-magnify</v-icon> Buscar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </template>
  
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.periodo }}</td>
                
               <!-- <td>
                  {{
                    item.nombreMaximaAutoridad != null
                      ? item.nombreMaximaAutoridad
                      : ""
                  }}
                </td>-->
                <td>
                  <v-chip
                    class="ma-2 font-weight-medium"
                    label
                    :color="
                      item.estadosId === 1
                        ? 'blue-grey lighten-4'
                        : item.estadosId === 2
                        ? 'deep-orange lighten-4'
                        : item.estadosId === 6
                        ? 'cyan lighten-4'
                        : item.estadosId === 7
                        ? 'deep-orange lighten-4'
                        : 'pink lighten-4'
                    "
                    :text-color="
                      item.estadosId === 1
                        ? 'blue-grey lighten-1'
                        : item.estadosId === 2
                        ? 'deep-orange lighten-1'
                        : item.estadosId === 6
                        ? 'cyan darken-1'
                        : item.estadosId === 7
                        ? 'deep-orange lighten-1'
                        : 'pink darken-1'
                    "
                    small
                  >
                    {{ item.estado }}
                  </v-chip>
                </td>
                <td>
                  <v-btn
                    class="
                      ma-2
                      btn-bg-light
                      blue-grey--text
                      lighten-2--text
                      font-weight-medium
                      text-capitalize
                    "
                    small
                    depressed
                    color="blue-grey lighten-5"
                    @click="obtenerDatosItem(item.id)"
                  >
                    <v-icon left>mdi-pencil</v-icon> Actualizar
                  </v-btn>         
  
                  <v-btn
                    
                    class="
                      ma-2
                      btn-bg-light
                      blue-grey--text
                      lighten-2--text
                      font-weight-medium
                      text-capitalize
                    "
                    small
                    depressed
                    color="blue-grey lighten-5"
                    @click="mostrarDialogEstadoItem(item.id, item.estadosId, item.estado, item.nombreActividad)"
                  >
                    <v-icon left>{{item.estadosId===1 ? `mdi-delete` : `mdi-file-check-outline`}}</v-icon> {{item.estadosId===1 ? `Inhabilitar` : `Habilitar`}}
                  </v-btn>                
  
                  
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <!-- <template v-slot:html>
              {{ code4.html }}
            </template>
            <template v-slot:js>
              {{ code4.js }}
            </template> -->
      </v-card>
  
      <!--begin:: dialog suspension -->
      <v-dialog v-model="dialogSuspension" width="500" transition="scroll-y-transition">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Suspensión del proyecto
          </v-card-title>
  
          <v-card-text>
            <v-row>
              <v-col sm="12">
                Está seguro de suspender el proyecto {{ proyectoSeleccionado }}
              </v-col>
            </v-row>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              Continuar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--end: dialog-->
  
      <!--begin:: modal registro-->
      <v-dialog
        v-model="dialogRegistro"
        max-width="900"
        transition="scroll-y-transition"
        persistent
        scrollable
      >
        <v-card tile>
          <v-card-title>
            {{ modalTitle }}
  
            <v-spacer></v-spacer>
            <v-btn
              icon
              :disabled="btnRegistroLoading"
              @click="dialogRegistro = false || resetForm()"
              class="float-right"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="d-none"></v-divider>
  
          <v-card-text class="pl-8 pr-8 pt-5">
            <v-form
              ref="form"
              v-on:submit.prevent="registrarItem"
              v-model="validForm"
            >
              <v-row>
                <v-col cols="12" md="12" class="pt-4 pb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosItem.periodo"
                    label="Periodo / Año"
                    :rules="[
                      required('periodo'),
                      minLength('periodo', 4),
                      maxLength('periodo', 4),
                      onlyInteger('periodo')
                    ]"
                    maxlength="4"
                  ></v-text-field>
                </v-col>
              </v-row>
  
              <!-- <v-row>
                <v-col md="12" sm="12" class="pt-0 pb-0">
                  <label class="grey--text body-2 mb-0 font-weight-medium"
                    >Estado</label
                  >
                  <template>
                    <v-switch
                      class="pt-0 mt-1"
                      v-model="switchItemEstado"
                      inset
                      :label="switchItemEstado ? 'Activo' : 'Inactivo'"
                    >
                    </v-switch>
                  </template>
                </v-col>
              </v-row> -->
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey lighten-5"
              elevation="0"
              class="mb-2 float-right grey lighten-5"
              @click="
                dialogRegistro = false;
                resetForm();
              "
              :disabled="btnRegistroLoading"
            >
              Cancelar
            </v-btn>
            <!--:disabled="!validDocForm" type="submit"-->
            <v-btn
              color="light-blue-502"
              class="white--text mb-2 float-right"
              type="submit"
              :elevation="0"
              :disabled="!validForm"
              :loading="btnRegistroLoading"
              @click="registrarItem"
            >
              {{btnRegistroText}}
            </v-btn>
          </v-card-actions>
  
          <div style="flex: 1 1 auto"></div>
        </v-card>
      </v-dialog>
  
  
      <!--dialog para confirmar el cambio del estado-->
          <v-dialog v-model="dialogConfirmarEstado" transition="scroll-y-transition" max-width="500">
          <v-card>
              <v-card-title>
                Confirmar
              <v-spacer></v-spacer>
              <v-btn
                  icon
                  :disabled="false"
                  @click="dialogConfirmarEstado = false"
                  class="float-right"
              >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
              </v-card-title>
              <v-card-text style="letter-spacing: 0.1px !important">
              
              <v-row class="mt-2">
                  <v-col cols="12" md="12" xs="12">
                    <p class="text-h6 black--text  pb-0 mb-0"> Está seguro de cambiar la actividad "{{nombreItem}}" al estado {{nuevoEstado}}?</p> 
                  </v-col>
  
                 
  
              </v-row>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey lighten-5"
                elevation="0"
                class="mb-2 float-right grey lighten-5"
                @click="dialogConfirmarEstado = false;"
                :disabled="btncambioEstadoLoading"
              >
                Cancelar
              </v-btn>
              <!--:disabled="!validDocForm" type="submit"-->
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right"
                type="submit"
                :elevation="0"
                :loading="btncambioEstadoLoading"
                @click="actualizarEstadoItem(datosItem.id, nuevoEstadoId)"
              >
                Sí
              </v-btn>
            </v-card-actions>
          </v-card>
          </v-dialog>
          <!--Dialog mapa-->
  
  
      <!--end:: modal registro-->
  
      <!--Dialog loader -->
      <DialogLoader
        :dialogVisible="dialogLoaderVisible"
        :text="dialogLoaderText"
      ></DialogLoader>
      <!---->
  
      <!--Inicio:: Snack alert-->
      <SnackAlert ref="snackalert"></SnackAlert>
      <!-- Fin:: Snack alert-->
    </div>
  </template>
  
  <script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import DialogLoader from "@/view/content/DialogLoader";
  
  import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
  
  import validations from "@/core/untils/validations.js";
  
  import { OBTENER_PERIODOS, ELIMINAR_PERIODO, OBTENER_PERIODO, REGISTRAR_PERIODO, ACTUALIZAR_PERIODO } from "@/core/services/store/catalogos/periodos/periodo.module";
  
  
  export default {
    name: "AdminPeriodos",
    data() {
      return {
        validForm: false,
        ddRolesLoading: false,
        dialogRegistro: false,
        dialogConfirmarEstado: false,
        dialogLoaderVisible:false,
        dialogLoaderText: '',
        tableLoading: false,
        dialogSuspension: false,
        switchItemEstado: true,
        proyectoSeleccionado: "",
        filtro: "",
        dialog: false,
        modalTitle: "Registrar Periodo",
        accion: 1,
        items: [],
        btnRegistroText: "Guardar",
        btnRegistroLoading: false,
        estadoProyecto: "",
        datosItem: {
          id: 0,
          periodo: '',
          estadosId: 1
        },
        estado: "",
        estados: [
          { text: "Todos", value: null },
          { value: 1, text: "Activo" },
          { value: 2, text: "Inactivo" }
        ],
        nuevoEstado: "",
        nuevoEstadoId:0,
        nombreItem: "",
        btncambioEstadoLoading: false,  
        ...validations,
       
  
        
      };
    },
    components: {
      DialogLoader,
      SnackAlert
    },
    methods: {
      filtroEstado(value) {
        // If this filter has no value we just skip the entire filter.
        if (!this.estado.value) {
          return true;
        }
        // Check if the current loop value (The calories value)
        // equals to the selected value at the <v-select>.
        return value === this.estado.text;
      },
  
       /// Obtener unidades ejecutoras
      async obtenerItems() {
        this.items = [];
        this.tableLoading = true;
  
        this.$store
          .dispatch(OBTENER_PERIODOS, 0)
          .then(res => {
  
            this.items = res.data;
            this.tableLoading = false;
          })
          .catch(() => {
            this.tableLoading = false;
          });
      },
  
     
  
      modalNuevo() {
        this.accion = 1;
        this.modalTitle = "Registrar Periodo";
        this.btnRegistroText = "Guardar";
        this.switchItemEstado = true;
        this.dialogRegistro = true;
      },
  
  
  
      ///Registror unidad ejecutora
      registrarItem() {
        this.btnRegistroLoading = true;
  
        //!Asignar el estado a datos empresa segun lo seleccionado en el swithc del formulario
        this.switchItemEstado
          ? (this.datosItem.estadosId = 1)
          : (this.datosItem.estadosId = 2);
  
        this.datosItem.usuarioCreacion= "test";
  
        //?Si accion es 1 se realiza el registro
        if (this.accion === 1) {
          this.$store
            .dispatch(REGISTRAR_PERIODO, this.datosItem)
            .then(res => {
              this.btnLoading = false;
              //this.dialogLoaderVisible = false;
              if (res.status === 200) {
                this.$refs.snackalert.SnackbarShow(
                  "success",
                  "Mensaje",
                  res.message
                );
                this.obtenerItems();
                this.dialogRegistro = false;
                this.resetForm();
              } else {
                this.$refs.snackalert.SnackbarShow(
                  "warning",
                  "Alerta",
                  res.message
                );
              }
              this.btnRegistroLoading = false;
            })
            .catch(() => {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                `Ha ocurrido un error durante el registro.`
              );
            });
        }
        //?Si accion es 2 se realiza la actualizacion
        else {
          this.$store
            .dispatch(ACTUALIZAR_PERIODO,  this.datosItem)
            .then(res => {
              this.btnLoading = false;
              this.dialogLoaderVisible = false;
              this.btnRegistroLoading = false;
  
              if (res.status === 200) {
                this.$refs.snackalert.SnackbarShow(
                  "success",
                  "Mensaje",
                  res.message
                );
                this.dialogRegistro = false;
                this.obtenerItems();
                this.resetForm();
              } else {
                this.$refs.snackalert.SnackbarShow(
                  "warning",
                  "Alerta",
                  res.message
                );
              }
            })
            .catch(() => {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                `Ha ocurrido un error durante el registro.`
              );
            });
        }
      },
  
  
      async actualizarEstadoItem(){
         //?Si accion es 2 se realiza la actualizacion
          this.btncambioEstadoLoading=true;
          this.$store
            .dispatch(ELIMINAR_PERIODO, this.datosItem)
            .then(res => {
            
              if (res.status === 200) {
                this.$refs.snackalert.SnackbarShow(
                  "success",
                  "Mensaje",
                  res.message
                );
                this.dialogRegistro = false;
                this.obtenerItems();
                this.resetForm();
                this.dialogConfirmarEstado=false;
              } else {
                this.$refs.snackalert.SnackbarShow(
                  "warning",
                  "Alerta",
                  res.message
                );
              }
              this.btncambioEstadoLoading=false;
            })
            .catch(() => {
              this.btncambioEstadoLoading=false;
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                `Ha ocurrido un error durante el registro.`
              );
            });
        
      },
      
  
      //!Obtener datos de un item especifico
      async obtenerDatosItem(Id) {
       
        this.dialogLoaderVisible = true;
        this.dialogLoaderText = "Obteniendo información...";
        this.dialogTitulo = `Actualizar actividad`;
       
        this.btnRegistroText = `Actualizar`;
        this.datosItem={};
        await this.$store
          .dispatch(OBTENER_PERIODO, Id)
          .then(res => {
            //console.log(res);
            if (res.status === 200) {
              this.datosItem = res.data;
              this.datosItem.estadosId === 1
                ? (this.switchItemEstado = true)
                : (this.switchItemEstado = false);
              this.accion = 2;
               this.modalTitle = `Actualizar Periodo ${this.datosItem.nombreUnidadEjecutora}`
              this.dialogRegistro = true;
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
              );
            }
            this.dialogLoaderVisible = false;
          })
          .catch(error => {
            console.log(error)
            this.dialogLoaderVisible = false;
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
            );
            
          });
      },
  
  
      mostrarDialogEstadoItem(id, estadoIdActual, estadoActual, nombreItem){
  
        this.nuevoEstado= estadoIdActual === 1 ? "Inactivo" : "Activo";
        this.datosItem.nuevoEstadoId = estadoIdActual === 1 ? 2 : 1;
        this.nombreItem = nombreItem,
        this.datosItem.id = id;
        this.datosItem.estadosId = estadoIdActual;
        this.dialogConfirmarEstado=true;
      },
  
  
      
  
      cerrarModalRegistro() {
        //this.dialogRegistro = false;
      },
  
      //Mostrar el dialogo para la suspension
      mostrarDialogSuspension(nombreProyecto) {
        this.proyectoSeleccionado = nombreProyecto;
        this.dialogSuspension = true;
      },
  
      resetForm() {
  
        this.datosItem = {
          perido: '',
          estadosId: 1
        }
  
      },
    
    },
    created(){
      this.obtenerItems();
      
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Inicio", route: "dashboard" },
        { title: "Administracion" },
        { title: "Periodos" }
      ]);
    }, 
  
    computed: {
      headers(){
       return [
          {
            text: "Id",
            align: "start",
            sortable: false,
            value: "id"
          },
          {
            text: "Periodo",
            align: "start",
            sortable: true,
            value: "periodo"
          },
          {
            text: "Estado",
            align: "start",
            sortable: true,
            value: "estado",
            filter: this.filtroEstado
          },
          {
            text: "Acciones",
            align: "start",
            sortable: false,
            value: ""
          }
        ]
      }
    }
  };
  </script>
  